'use strict';

export default class FullscreenMenu {
  constructor() {
    $('#fullscreen').on('click', function() {
      $('.main-block').addClass('blur');
      $('.sticky-offert').addClass('offert-hide');
      $('#fullscreen-close').addClass('is-active');
      $(this).addClass('is-active');
      $('#fullscreen-menu').delay(300).fadeIn();
      $('#fullscreen-menu .anim-link').each(function(index) {
        $(this).addClass('animated fadeInUp delay'+(index + 1));
      });
      $('html').addClass('no-overflow');
    });
    $('#fullscreen-close').on('click', function() {
      $(this).removeClass('is-active');
      $('#fullscreen').removeClass('is-active');
      $('#fullscreen-menu').fadeOut(400);
      $('#fullscreen-menu .anim-link').each(function(index) {
        $(this).removeClass('animated fadeInUp delay'+(index + 1));
      });
      $('.main-block').removeClass('blur');
      $('.sticky-offert').removeClass('offert-hide');
      $('html').removeClass('no-overflow');
    });
    $('#fullscreen-menu .nav-item').on('click', function() {
      $('#fullscreen').removeClass('is-active');
      $('.main-block').removeClass('opacity');
      $('#fullscreen-menu').slideUp();
      $('#fullscreen-menu .nav li').each(function(index) {
        $(this).removeClass('animated fadeInUp delay'+(index + 1)+'');
      });
      $('html').removeClass('no-overflow');
    });
  }
}
