module.exports={
  "name": "chuzeat-static",
  "version": "1.1.0",
  "author": "Foodea Lab srl",
  "private": true,
  "dependencies": {
    "acorn": "^6.1.1",
    "animsition": "^4.0.2",
    "aos": "^3.0.0-beta.6",
    "babel-register": "^6.26.0",
    "jquery": "~3.1.1",
    "jquery-validation": "^1.19.3",
    "merge-stream": "^2.0.0",
    "normalize.css": "~5.0.0",
    "popper.js": "^1.15.0",
    "wow.js": "^1.2.2"
  },
  "devDependencies": {
    "@babel/core": "^7.13.15",
    "@babel/preset-env": "^7.13.15",
    "@babel/register": "^7.13.14",
    "animate.css": "^3.7.2",
    "autoprefixer": "~6.7.5",
    "babel-eslint": "^10.1.0",
    "babelify": "^10.0.0",
    "bootstrap": "v4.3.1",
    "browser-sync": "^2.26.14",
    "browserify": "^17.0.0",
    "del": "^6.0.0",
    "envify": "~4.0.0",
    "foldero": "~0.1.1",
    "glob": "~7.1.1",
    "gsap": "^2.1.3",
    "gulp": "^4.0.2",
    "gulp-babel": "^8.0.0",
    "gulp-changed": "^4.0.2",
    "gulp-cssnano": "~2.1.2",
    "gulp-data": "^1.3.1",
    "gulp-eslint": "^6.0.0",
    "gulp-htmlmin": "^5.0.1",
    "gulp-if": "^3.0.0",
    "gulp-imagemin": "^7.1.0",
    "gulp-load-plugins": "^2.0.6",
    "gulp-notify": "^3.2.0",
    "gulp-nunjucks-html": "~2.0.0",
    "gulp-plumber": "^1.2.1",
    "gulp-postcss": "^9.0.0",
    "gulp-rename": "^2.0.0",
    "gulp-rev": "^9.0.0",
    "gulp-sass": "^4.1.0",
    "gulp-sitemap": "^8.0.0",
    "gulp-sourcemaps": "^3.0.0",
    "gulp-uglify": "^3.0.2",
    "gulp-util": "~3.0.8",
    "hamburgers": "^1.1.3",
    "js-yaml": "~3.8.1",
    "lodash": "^4.17.21",
    "minimist": "~1.2.0",
    "nunjucks": "^3.2.3",
    "paroller.js": "^1.4.6",
    "rimraf": "^3.0.2",
    "scrollmagic": "^2.0.7",
    "swiper": "^4.5.0",
    "tilt.js": "^1.2.1",
    "vinyl-buffer": "~1.0.0",
    "vinyl-source-stream": "^2.0.0",
    "watchify": "~3.9.0 "
  },
  "scripts": {
    "clean-deps": "rimraf node_modules",
    "clean": "gulp clean",
    "lint": "gulp eslint",
    "dev": "NODE_ENV=development gulp serve",
    "serve": "NODE_ENV=production gulp serve --production",
    "build": "NODE_ENV=production gulp --production && git rev-parse HEAD > build/VERSION.md"
  },
  "engines": {
    "node": "~12.22.7"
  },
  "config": {
    "host": "127.0.0.1",
    "port": "3000",
    "baseUrl": "./",
    "directories": {
      "source": "src",
      "destination": "build",
      "temporary": "tmp",
      "modules": "_modules",
      "layouts": "_layouts",
      "images": "_images",
      "styles": "_styles",
      "scripts": "_scripts",
      "data": "_data"
    },
    "entries": {
      "js": "main**.js",
      "css": "main**.{sass,scss}"
    }
  }
}
