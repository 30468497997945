// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
window.$ = $;
import Link from '../_modules/link/link';
import 'bootstrap';
// import Popper from 'popper.js';
import 'paroller.js';
import Swiper from 'swiper';
import WOW from 'wow.js';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_it.min.js';
import FullscreenMenu from '../_modules/fullscreen-menu/fullscreen-menu';
import { name, version } from '../../package.json';

console.info(`${name} release ${version}`);

function onPageResize() {
  $('.hero-block').css('height', $('.iphones').outerHeight() + 100);
  $('.circle-calc-h').each((i, el) => {
    $(el).css('height', $(el).outerWidth());
  });
  $('.circle-calc-w').each((i, el) => {
    $(el).css('width', $(el).height());
  });
}

window.addEventListener('resize', onPageResize);

document.addEventListener('DOMContentLoaded', function () {
  new WOW().init();
  new Link();

  // common data
  new FullscreenMenu(); // get FullscreenMenu js
  $('.scroll-to-section-animate').on('click', (e) => {
    e.preventDefault();
    let sectionPage = $(e.currentTarget).data('section');
    $('html, body').animate({ scrollTop: $(sectionPage).offset().top }, 800);
  });
  let page = $('main').data('page');

  // carousels
  const howWorksSlide = new Swiper('#how-works-slide', {
    slidesPerView: 1,
    spaceBetween: 50,
    init: false,
    pagination: {
      el: '#how-works-slide .swiper-pagination',
      type: 'progressbar',
    },
  });

  const managePayment = new Swiper('#manage-payment-slide', {
    slidesPerView: 3,
    spaceBetween: 50,
    allowTouchMove: false,
    init: false,
    pagination: {
      //el: '#manage-payment-slide .swiper-pagination',
      //type: 'progressbar',
    },
    breakpoints: {
      //when window width is <= 640px
      991: {
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
      },
    },
  });

  const teamSlide = new Swiper('#team-slide', {
    slidesPerView: 5,
    spaceBetween: 40,
    allowTouchMove: false,
    init: false,
    navigation: {
      nextEl: '.team-next',
      prevEl: '.team-prev',
    },
    breakpoints: {
      991: {
        slidesPerView: 3,
        spaceBetween: 40,
        allowTouchMove: true,
      },
      575: {
        slidesPerView: 1,
        spaceBetween: 40,
        allowTouchMove: true,
      },
    },
  });

  /*const tabsSlide = new Swiper('#tabs-slide', {
    slidesPerView: 2,
    spaceBetween: 30,
    init: false,
    pagination: {
      el: '#tabs-slide .swiper-pagination',
      type: 'progressbar',
    },
    breakpoints: {
      500: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      // when window width is <= 640px
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  });*/

  const galleryTop = new Swiper('#business-activity-slide', {
    slidesPerView: 4,
    spaceBetween: 20,
    init: false,
    pagination: {
      el: '.business-activity-slide-pag .swiper-pagination',
      type: 'progressbar',
    },
    navigation: {
      nextEl: '.business-next',
      prevEl: '.business-prev',
    },
    breakpoints: {
      1620: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      780: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      450: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
    },
  });

  const partnerSlider = new Swiper('#partner-slider', {
    slidesPerView: 1,
    allowTouchMove: false,
    autoHeight: true,
    pagination: {
      el: '.partner-slider-pg .swiper-pagination',
      type: 'progressbar',
    },
  });

  // utility functions
  function checkShadowPanel() {
    if (partnerSlider.activeIndex == partnerSlider.slides.length - 1) {
      $('.shadow-panel').fadeOut();
    } else {
      $('.shadow-panel').fadeIn();
    }
    $('html, body').animate(
      { scrollTop: $('#partner-slider').offset().top - 200 },
      200
    );
  }

  function validatePanels() {
    switch (partnerSlider.activeIndex) {
    case 2:
      $('.form-wrapp-all').validate({
        rules: {
          name: {
            required: true,
          },
          surname: {
            required: true,
          },
          company_name: {
            required: true,
          },
          address: {
            required: true,
          },
          city: {
            required: true,
          },
          province: {
            required: true,
          },
          postal_code: {
            required: true,
          },
          email: {
            required: true,
          },
          phone: {
            required: true,
          },
        },
        errorPlacement: function (error, element) {
          error.insertAfter(element);
        },
        errorClass: 'input-error d-block',
        errorElement: 'span',
        highlight: function () {},
        unhighlight: function () {},
        submitHandler: function () {},
      });
      if ($('.form-wrapp-all').valid()) {
        // let restaurantName = $('#restaurant-name').val();
        let restaurantNumber = $('#restaurant-number').val();
        let restaurantSeat = $('#restaurant-seat').val();
        let name = $('#user-name').val();
        let surname = $('#user-surname').val();
        let companyName = $('#user-company-name').val();
        let address = $('#user-address').val();
        let city = $('#user-city').val();
        let province = $('#user-province').val();
        let postalCode = $('#user-postal-code').val();
        let email = $('#user-email').val();
        let phone = $('#user-phone').val();
        let gdpr = $('#accettazione-gdpr').val();

        // post contact data to Zoho CRM
        const mappedFields = {
          CONTACTCF52: restaurantNumber,
          CONTACTCF51: restaurantSeat,
          'First Name': name,
          'Last Name': surname,
          'Account Name': companyName,
          'Mailing Street': address,
          'Mailing City': city,
          'Mailing State': province,
          'Mailing Zip': postalCode,
          Email: email,
          Phone: phone,
        };
        /**
             * staticFields are a combination of fields from the Zoho form and
             * data injected via the analytics script
             */
        const staticFields = {
          xnQsjsdp:
                '5e63d97a5a71d131b670531eaab98cc230f78729d2e46a386facbc4bec595341',
          zc_gad: '',
          xmIwtLD:
                'f737572307d15d6e1f435119c7677d6f213e937c8f516a1d0a49466129c7ee8b',
          actionType: 'Q29udGFjdHM=',
          returnURL:
                'https://www.chuzeat.com/ristoratori/#diventapartner',
          'Lead Source': 'Modulo Web',
          CONTACTCF2: 'v2.0',
          privacyTool: gdpr,
          eo: 'c4408f450319003e0c12e54df63a8632',
          te: 'true',
          la: '3307d14ad99b0c735620a072391c6a7a',
        };
        $.ajax({
          url: 'https://crm.zoho.eu/crm/WebToContactForm',
          method: 'POST',
          data: {
            ...mappedFields,
            ...staticFields,
          },
          success: () => {
            dataLayer &&
                  dataLayer.push &&
                  dataLayer.push({
                    event: 'diventa-partner-sent',
                    event_category: 'info',
                    event_label: 'request',
                  });
            $('.form-wrapp-all').find('.go-ahead').text('Conferma');
            partnerSlider.slideNext();
            $(
              '.partner-slider-pg, .wrapp-cancel-request, .wrapp-footer'
            ).fadeOut();
            $('.form-wrapp-all')[0].reset();
          },
          error: (err) => {
            $('.form-wrapp-all').find('.go-ahead').text('Conferma');
            partnerSlider.slideNext();
            $(
              '.partner-slider-pg, .wrapp-cancel-request, .wrapp-footer'
            ).fadeOut();
            $('.form-wrapp-all')[0].reset();
            window.location.href =
                  'mailto:help@chuzeat.com?subject=Diventa partner&body=' +
                  Object.entries(mappedFields)
                    .map(function (c) {
                      return c[0].toUpperCase() + '=' + c[1];
                    })
                    .join('%0D%0A');
            dataLayer &&
                  dataLayer.push &&
                  dataLayer.push({
                    event: 'diventa-partner-error',
                    event_category: 'error',
                    event_label: err && err.error,
                  });
          },
        });
        //callback ajax
        // $.ajax({
        //   url:'https://usebasin.com/f/586b6346c11f.json',
        //   method:'POST',
        //   dataType:'json',
        //   data: {
        //     'Numero di ristoranti': restaurantNumber,
        //     'Numero medio di coperti a settimana': restaurantSeat,
        //     Nome: name,
        //     Cognome: surname,
        //     'Ragione Sociale': companyName,
        //     Indirizzo: address,
        //     Città: city,
        //     Provincia: province,
        //     Cap: postalCode,
        //     Email: email,
        //     Telefono: phone,
        //   },
        //   beforeSend: function(jqXHR) {
        //     // show spinner button
        //     $('.form-wrapp-all').find('.go-ahead').text('Sto inviando');
        //     // formContactButtonSpinner.css('opacit', 1);
        //   },
        //   success: function(data) {
        //     $('.form-wrapp-all').find('.go-ahead').text('Conferma');
        //     partnerSlider.slideNext();
        //     $('.partner-slider-pg, .wrapp-cancel-request, .wrapp-footer').fadeOut();
        //     $('.form-wrapp-all')[0].reset();
        //   }
        // });
      }
      $('.form-wrapp-all').valid();
      break;
    default:
      if (currentVal <= 0 || currentVal == null) {
        $('.became-partner-cta .steps-block').addClass('shake');
        setTimeout(function () {
          $('.became-partner-cta .steps-block').removeClass('shake');
        }, 600);
        return false;
      }
      return true;
    }
  }

  function quantityClicks() {
    // let counterClick = $('.swiper-slide-active .increment-number').val();
    $(document).on('click', '.btn-quantity', function (e) {
      let counterClick = $('.swiper-slide-active .increment-number').val();
      e.preventDefault();
      let action = $(e.currentTarget).attr('data-action');
      // let value = $('.swiper-slide-active .increment-number').data('value');
      let max = $(
        '.swiper-slide-active .btn-quantity[data-action="more"]'
      ).attr('data-max');
      let min = $(
        '.swiper-slide-active .btn-quantity[data-action="less"]'
      ).attr('data-min');
      if (action == 'more' && counterClick < parseInt(max)) {
        counterClick++;
      } else if (action == 'less' && counterClick > parseInt(min)) {
        counterClick--;
      }
      $('.swiper-slide-active .increment-number').val(counterClick);
    });
  }

  const currentVal = $('.swiper-slide-active .increment-number').val();


  switch (page) {
  case 'home':
    $(window).on('scroll', () => {
      let scroll = $(window).scrollTop();
      if (
        scroll > 0 &&
          scroll <= $('.hero').offset().top + $('.hero').outerHeight()
      ) {
        $('.iphone-left .parallax-custom').css({
          transform: 'translateY(-' + parseInt(scroll / 10) + 'px)',
        });
        $('.iphone-right .parallax-custom').css({
          transform: 'translateY(' + parseInt(scroll / 8) + 'px)',
        });
      }
    });
    howWorksSlide.init();
    break;
  case 'how-it-works':
    /*$(window).on('scroll', () => {
      let scroll = $(window).scrollTop();
      if (
        scroll >= $('.qr-code').offset().top - 300 &&
          scroll <= $('.qr-code').offset().top + $('.qr-code').height()
      ) {
        let price = 1642;
        $('#increment-n')
          .prop('Counter', $('#increment-n').text())
          .animate(
            {
              Counter: price,
            },
            {
              duration: 700,
              easing: 'linear',
              step: function (now) {
                $('#increment-n').text(Math.ceil(now));
              },
            }
          );
      }
    });*/
    managePayment.init();
    break;
  case 'chuzeat-social':
    // let projectNav = new Swiper('#nav-for-project', {
    //   slidesPerView: 1,
    //   allowTouchMove: false,
    //   watchSlidesVisibility: true,
    //   watchSlidesProgress: true,
    // });
    // let projectSlide = new Swiper('#project-slide', {
    //   slidesPerView: 1,
    //   allowTouchMove: true,
    //   init: false,
    //   navigation: {
    //     nextEl: '.project-next',
    //     prevEl: '.project-prev',
    //   },
    //   thumbs: {
    //     swiper: projectNav
    //   }
    // });
    // projectSlide.init();
    break;
  case 'chi-siamo':
    teamSlide.init();
    break;
  case 'business':
    $(window).on('scroll', () => {
      let scroll = $(window).scrollTop();
      if (
        scroll > 0 &&
          scroll <= $('.hero').offset().top + $('.hero').outerHeight()
      ) {
        $('.parallax-custom').css({
          transform: 'translateX(' + parseInt(scroll / 6) + 'px)',
        });
      }
    });
    //tabsSlide.init();
    galleryTop.init();
    quantityClicks();
    $('.go-ahead, .start-form').on('click', (e) => {
      e.preventDefault();
      if (validatePanels()) partnerSlider.slideNext();
      checkShadowPanel();
      // quantityClicks();
    });
    $('.go-back').on('click', (e) => {
      e.preventDefault();
      partnerSlider.slidePrev();
      checkShadowPanel();
      // quantityClicks();
    });
    $('.cancel-request').on('click', (e) => {
      e.preventDefault();
      partnerSlider.slideTo(0);
      checkShadowPanel();
      $('.form-wrapp-all')[0].reset();
      // quantityClicks();
    });
    break;
  case 'faq':
    $('#collapse').collapse();
    break;
  }
  onPageResize();
  setTimeout(function () {
    $('.wrapp-loader').fadeOut();
  }, 300);
});
